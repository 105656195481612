import api from '@/api'

export default class ProjectsApi {
	static async getShops(data = {}) {
		const http = api();
		return http.post('/shop/all', data).then(function (response) {
			return response.data
		})
	}

	static async getShop(data = {}) {
		const http = api();
		return http.post('/shop/one', data).then(function (response) {
			return response.data
		})
	}

	static async getCoupons(data = {}) {
		const http = api();
		return http.post('/coupon/all', data).then(function (response) {
			return response.data
		})
	}

	static async getCoupon(data) {
		const http = api();
		return http.post('/coupon/one', data).then(function (response) {
			return response.data
		})
	}

	static async getCouponComplex(data) {
		const http = api();
		return http.post('/coupon/complex', data).then(function (response) {
			return response.data
		})
	}

	static async getCouponOnce(data) {
		const http = api();
		return http.post('/coupon/coupon-from-list', data).then(function (response) {
			return response.data
		})
	}

	static async sendToBot(data) {
		const http = api();
		return http.post('/coupon/to-bot', data).then(function (response) {
			return response.data
		})
	}

	static async stat(data) {
		const http = api();
		return http.post('/stat', data).then(function (response) {
			return response.data
		})
	}
}
