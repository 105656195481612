import { BackButton as BackButtonBase } from '@vkruglikov/react-telegram-web-app';
import { useNavigate, useLocation } from 'react-router-dom';

export default function BackButton() {
	const navigate = useNavigate()
	const location = useLocation()
	const back = () => {
		navigate(location.state.from)
		window.Telegram.WebApp.BackButton.hide()
	}

	return (
		location.state !== null && location.state.from ? <BackButtonBase onClick={back}></BackButtonBase> : null
	)
}
