import { useEffect, useState } from 'react'
import ProjectsSearch from './ProjectsSearch'
import { Link, useLocation } from 'react-router-dom'
import ProjectsApi from '../api';
import ShareButton from '@/components/ShareButton';
import { useInitData } from '@vkruglikov/react-telegram-web-app';

export default function ProjectsList(props) {
	const location = useLocation()
	const [shops, setShops] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [initDataUnsafe, initData] = useInitData();

	useEffect(() => {
		let cat = props.categoryId ? props.categoryId : false
		ProjectsApi.getShops({category: cat, initData: initData}).then(function(result) {
			if (result.data) {
				setShops(result.data.shops)
				setFiltered(result.data.shops)
			}
		})
	}, [props, initData])

	const filter = (text) => {
		if (text === '') {
			setFiltered(shops)
		} else {
			let array = []
			shops.forEach(shop => {
				if (shop.title.toLowerCase().includes(text.toLowerCase()) || (shop.search && shop.search.toLowerCase().includes(text.toLowerCase()))) array.push(shop)
			})
			setFiltered(array)
		}
	}

	return (
		<>
			{props.search && <ProjectsSearch filter={filter} />}

			<div className='boxFolder'>
				{filtered.map((shop) => (
					<Link to={'/projects/' + shop.id} className='boxFolderItem' key={shop.id} state={{shop: shop, from: location.pathname}}>
						{shop.image && <img src={shop.host + '/i/100x100/' + shop.image} alt='' className='boxFolderItem-img' />}
						<div className='boxFolderItem-info'>
							<div className='boxFolderItem-name'>{shop.title}</div>
							<div className='boxFolderItem-ticket'>{shop.category}</div>
						</div>
					</Link>
				))}
			</div>

			{!props.search && shops.length > 0 && <ShareButton url={'CT' + location.state.cat.id} text={location.state.cat.title} />}
		</>
	)
}
