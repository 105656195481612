import axios from 'axios'

const api = () => {
	axios.defaults.baseURL = process.env.REACT_APP_API_PROTOCOL + '://' + process.env.REACT_APP_API_HOST

	axios.interceptors.request.use(
		(config) => {
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(function (response) {
		return response
	}, function (error) {
		if (error.response.status === 401) {
			return false;
		}

		if ((error.response.status === 422 && error.response.data.message) || error.response.status !== 422) {
			return false;
		}
		return error.response
	});

	return axios
}
export default api
