import { useLocation, useParams } from "react-router-dom";
import Project from "../components/Project";
import Page from '@/components/Page'
import { useEffect, useState } from "react";
import ProjectsApi from "../api";
import { useInitData } from "@vkruglikov/react-telegram-web-app";

export default function ProjectPage() {
	const location = useLocation()
	const params = useParams()
	const [shop, setShop] = useState()
	const [initDataUnsafe, initData] = useInitData();

	useEffect(() => {
		if (params.id && (location.state === null || (location.state !== null && typeof location.state.shop === 'undefined'))) {
			ProjectsApi.getShop({id: params.id, initData: initData}).then(function(result) {
				if (result.data) {
					setShop(result.data.shop)
					location.state = {
						shop: result.data.shop,
						from: '/projects'
					}
				}
			})
		} else {
			setShop(location.state.shop)
		}
	}, [location, params])

	return (
		shop ?
			<Page title={shop.title}>
				<Project/>
			</Page>
		: null
	)
}
