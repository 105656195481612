import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomeRouter from '@Home/router'
import CategoriesRouter from '@Categories/router'
import ProjectsRouter from '@Projects/router'
import NotFoundPage from '@/pages/NotFoundPage'
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';
import ProviderRouter from './provider'

function AppRouter() {
    return (
        <BrowserRouter basename={'/'}>
			<ProviderRouter>
				<Header />
					<Routes>
						{ HomeRouter }
						{ CategoriesRouter }
						{ ProjectsRouter }
						<Route path='*' element={<NotFoundPage />} />
					</Routes>
				<Footer />
			</ProviderRouter>
        </BrowserRouter>
    );
}

export default AppRouter;
