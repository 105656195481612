import React from 'react';
import Seo from './Seo';
import BackButton from './BackButton';

export default function Page({ title, description, meta, children }) {

	return (
    <>
		<Seo
			title={title}
			description={description}
			meta={meta ? meta : []}
		/>

		<BackButton/>

		<main>
			{children}
		</main>
    </>
  );
}
