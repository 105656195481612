import { useState } from "react";
import { useEffect } from "react"
import './Header.scss'
import { Link, useLocation } from "react-router-dom";

export default function Header() {
	const location = useLocation();
	const [path, setPath] = useState('')
	useEffect(() => {
		setPath(location.pathname)
	}, [location])

	return (
		<header className={'Header' + (path.includes('categories') ? ' Header--active' : '')}>
			<Link to='/projects' className={'Header-link ' + (path.includes('projects') ? 'Header-link--active' : '')}>Проекты</Link>
			<Link to='/categories' className={'Header-link ' + (path.includes('categories') ? 'Header-link--active' : '')}>Категории</Link>
		</header>
	)
}
