import React from 'react';

export default function ErrorComponent() {
    return (
		<div className='page404'>
			<div className='container'>
				<h1>Упс! Что-то пошло не так...</h1>
				<h2>Страница не найдена.</h2>
			</div>
		</div>
    );
};
