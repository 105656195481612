import React, { useEffect } from 'react';
import Providers from './Providers';
import AppRouter from './router';
import { useExpand, useThemeParams, useWebApp } from '@vkruglikov/react-telegram-web-app';

export default function App() {
	const WebApp = useWebApp();
	const [isExpanded, expand] = useExpand();
	const [colorScheme, themeParams] = useThemeParams();

	useEffect(() => {
		WebApp.setHeaderColor('secondary_bg_color')
		WebApp.setBackgroundColor('secondary_bg_color')
		expand();
		// WebApp.enableClosingConfirmation();
		document.body.classList.add('theme-' + (colorScheme !== 'dark' ? 'light' : 'dark'));
	}, [WebApp, expand, colorScheme]);

	return (
		<Providers>
			<AppRouter />
		</Providers>
	);
};
