import api from '@/api'

export default class ShareApi {
	static async share(data = {}) {
		const http = api();
		return http.post('/share', data).then(function (response) {
			return response.data
		})
	}
}
