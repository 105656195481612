import './style.scss'
import AnimationCopy from '@/icons/animations/Copy.json'
import Lottie from "lottie-react"

export default function Alert(props) {
	return (
		<div className='Alert'>
			<Lottie animationData={AnimationCopy} className='AlertIcon' loop={false} />
			<div className='AlertText'>{props.children}</div>
		</div>
	)
}
