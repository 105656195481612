import { Route } from 'react-router-dom'
import ProjectsPage from './pages/ProjectsPage'
import ProjectPage from './pages/ProjectPage'
import ProjectDiscountPage from './pages/ProjectDiscountPage'

const routes = [
	<Route path={'/projects'} element={<ProjectsPage />} key='projects' />,
	<Route path={'/projects/:id'} element={<ProjectPage />} key='project' />,
	<Route path={'/projects/:id/discount/:discount'} element={<ProjectDiscountPage />} key='projectDiscount' />,
	<Route path={'/discount/:discount'} element={<ProjectDiscountPage />} key='projectDiscount' />,
]

export default routes
