import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import parse from 'html-react-parser'
import BottomSheet from "react-draggable-bottom-sheet";
import { HandySvg } from 'handy-svg'
import IconQuestion from '@/icons/question.svg'
import ProjectDiscountComplex from './complex';
import ProjectDiscountOnce from './once';
import ProjectDiscountSimple from './simple';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import ProjectsApi from '../../api';
import ShareButton from '@/components/ShareButton';

export default function ProjectDiscount() {
	const location = useLocation()
  	const shop = location.state.shop
  	const coupon = location.state.coupon
	const [initDataUnsafe, initData] = useInitData();

	const [isRefOpen, setIsRefOpen] = useState(false);

	const refClose = () => setIsRefOpen(false);

	useEffect(() => {
		ProjectsApi.stat({
			coupon: coupon.id,
			shop: shop.id,
			initData: initData
		})
	}, [])

	return (
		<>
			<div className='boxList'>
				<div className='boxList_item'>
					<div className='boxList_item-icon'>
						{shop.image && <img src={shop.host + '/i/100x100/' + shop.image} alt='' />}
					</div>
					<div className='boxList_item-content'>
						<div className='boxList_item-title'>{shop.title}</div>
						<div className='boxList_item-hint'>{shop.category}</div>
					</div>
					{shop.url &&
						<div className='boxList_item-right'>
							<a href={shop.url} rel='noreferrer' target='_blank' className='btn btn--sm'>Перейти</a>
						</div>
					}
				</div>
			</div>

			{shop.description &&
				<div className='boxText'>
					<div className='boxText_body'>
						{parse(shop.description)}
					</div>
				</div>
			}

			<div className='boxText'>
				<div className='boxText_header'>Описание</div>
				<div className='boxText_body'>
					<p><b>{coupon.title}</b></p>
					<p>{parse(coupon.description)}</p>
				</div>
			</div>

			{
				coupon.type_of_steps == 20 ?
					<ProjectDiscountComplex/>
				: coupon.type_of_steps == 10 ?
					<ProjectDiscountOnce/>
				:
					<ProjectDiscountSimple/>
			}

			<ShareButton style={{marginTop: '-31px'}} url={'CP' + coupon.id} text={shop.title + ' - ' + coupon.title} />

			<div className='boxButtons'>
				<button className='boxButtonsItem' onClick={() => setIsRefOpen(true)}>
					<div className='boxButtonsItem-icon'>
						<HandySvg src={IconQuestion} />
					</div>
					<div className='boxButtonsItem-info'>
						<div className='boxButtonsItem-text'>Скидка или промокод не работает?</div>
					</div>
				</button>
			</div>

			<BottomSheet isOpen={isRefOpen} close={refClose}>
				<button className='BottomSheetClose' onClick={refClose}>Закрыть</button>
				<div className='BottomSheetTitle'>Помощь</div>
				<div className='boxText'>
					<div className='boxText_body'>
						<p>Если не работает скидка или не применяется промокод, сообщите нам в обратную связь: <a href='https://t.me/SaleNewsBot' target='_blank' rel='noreferrer'>@SaleNewsBot</a></p>
						<p>Следите за обновлением свежих промокодов в канале Промопушка: <a href='https://t.me/joinchat/AAAAADwpXXeKpgkHAYRSlw' target='_blank' rel='noreferrer'>https://t.me/joinchat/AAAAADwpXXeKpgkHAYRSlw</a></p>
					</div>
				</div>
			</BottomSheet>
		</>
	)
}
