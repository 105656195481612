import React from 'react';
import Page from '@/components/Page';
import CategoriesList from '../components/CategoriesList';

export default function CategoriesPage() {
	return (
    	<Page title='Категории'>
			<CategoriesList/>
	    </Page>
  	);
}
