import './style.scss'
import { HandySvg } from 'handy-svg'
import IconSearch from '@/icons/search.svg'
import { useState } from 'react'

export default function ProjectsSearch(props) {
	const [filled, setFilled] = useState(false)
	const filter = (e) => {
		if (e.target.value.length > 0) {
			setFilled(true)
		} else {
			setFilled(false)
		}
		props.filter(e.target.value)
	}

	return (
		<form className={'ProjectsSearch' + (filled ? ' ProjectsSearch--filled' : '')}>
			<input className='ProjectsSearch-input' type='text' onInput={filter} />
			<div className='ProjectsSearch-placeholder'>
				<HandySvg src={IconSearch} className='ProjectsSearch-icon' />
				<span>Поиск</span>
			</div>
		</form>
	)
}
