import { Route } from 'react-router-dom'
import CategoriesPage from './pages/CategoriesPage'
import CategoryPage from './pages/CategoryPage'

const routes = [
	<Route path={'/categories'} element={<CategoriesPage />} key='categories' />,
	<Route path={'/categories/:id'} element={<CategoryPage />} key='category' />,
]

export default routes
