import { useHapticFeedback, useInitData, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ProjectsApi from '../../api'
import { MainButton } from '@vkruglikov/react-telegram-web-app';
import copy from "copy-to-clipboard";
import Alert from "@/components/Alert";

export default function ProjectDiscountOnce() {
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const [initDataUnsafe, initData] = useInitData();
	const WebApp = useWebApp();

	const location = useLocation()
  	const coupon = location.state.coupon
	const [copied, setCopied] = useState(false)

	const handleCopy = (e = false) => {
		copy(coupon.coupon)
		if (e) {
			if (copied) return false;
			e.target.innerText = 'Скопировано!'
			impactOccurred('medium')
			setCopied(true)
			setTimeout(() => {
				setCopied(false)
				e.target.innerText = coupon.coupon
			}, 3000)
		}
	}

	const [error, setError] = useState(false)
	const [loaded, setLoaded] = useState(false)

	const handleLoad = (e) => {
		setError(false)
		if (coupon.type_of_coupon != 10) {
			setLoaded(true)
			return true;
		}

		const data = {
			initData: initData,
			id: coupon.id
		}

		e.target.text = 'Загрузка...'
		e.target.disabled = true

		ProjectsApi.getCouponOnce(data).then(function(result) {
			e.target.disabled = false
			if (result.data.coupon) {
				notificationOccurred('success')
				coupon.coupon = result.data.coupon
				setLoaded(true)
			} else {
				notificationOccurred('error')
				e.target.text = 'Получить промокод'
				setLoaded(false)
				setError(result.data.error)
			}
		})
	}

	const sendToBot = () => {
		impactOccurred('medium')

		ProjectsApi.sendToBot({
			id: coupon.id,
			initData: initData
		}).then(function(result) {
			WebApp.close()
		})
	}

	return (
		<>
			<div className='boxText'>
				<div className='boxText_header'>Промокод</div>
				<div className='boxText_body'>
					{error && <p style={{color: 'red', textAlign: 'center'}}>{error}</p>}

					{!loaded ?
						<>
							<button className='btn btn--block' onClick={handleLoad}>Получить промокод</button>
						</>
					:
						<>
							<div className={'boxLink' + (copied ? ' boxLink--grey' : '')} onClick={handleCopy}>
								{coupon.coupon}
							</div>
							{coupon.url_to_activate &&
								<button className='btn btn--block btn--tt-none' onClick={() => {
									handleCopy()
									window.open(coupon.url_to_activate, '_blank').focus()
								}}>Скопировать и перейти на сайт</button>
							}
						</>
					}
				</div>
				<div className='boxText_footer' style={{paddingRight: '45px'}}>Материал был загружен: {coupon.published}</div>
			</div>
			<MainButton
				text='Посмотреть в боте'
				onClick={sendToBot}
			/>
			{copied && <Alert>Промокод скопирован.</Alert>}
		</>
	)
}
