import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProjectDiscount from "../components/ProjectDiscount";
import Page from '@/components/Page'
import { useEffect, useState } from "react";
import ProjectsApi from "../api";
import { useInitData } from "@vkruglikov/react-telegram-web-app";

export default function ProjectDiscountPage() {
	const location = useLocation()
	const params = useParams()
	const [coupon, setCoupon] = useState()
	const navigate = useNavigate()
	const [initDataUnsafe, initData] = useInitData();

	useEffect(() => {
		if (params.discount && (location.state === null || (location.state !== null && typeof location.state.coupon === 'undefined'))) {
			ProjectsApi.getCoupon({id: params.discount, initData: initData}).then(function(result) {
				if (result.data) {
					console.log(result.data)
					setCoupon(result.data.coupon)
					location.state = {
						coupon: result.data.coupon,
						shop: result.data.shop,
						from: '/projects/' + result.data.shop.id
					}
				} else {
					navigate('/projects')
				}
			})
		} else {
			setCoupon(location.state.coupon)
		}
	}, [location, params])

	return (
		coupon ?
			<Page title={coupon.title}>
				<ProjectDiscount/>
			</Page>
		: null
	)
}
