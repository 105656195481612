import { HandySvg } from 'handy-svg'
import './style.scss'
import IconShare from '@/icons/share.svg'
import { useHapticFeedback, useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app'
import ShareApi from './api'

export default function ShareButton(props) {
	const WebApp = useWebApp();
	const [initDataUnsafe, initData] = useInitData();
	const [impactOccurred, notificationOccurred] = useHapticFeedback();

	const handleShare = () => {
		impactOccurred('medium')
		ShareApi.share({
			initData: initData,
			data: {
				url: process.env.REACT_APP_WEB_APP_URL + '?startapp=' + props.url,
				text: props.text
			}
		}).then(function(result) {
			if (result) {
				notificationOccurred('success')
				WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT)
				WebApp.close()
			}
		})
	}
	return (
		<button onClick={handleShare} className='ShareButton' style={props.style}>
			<HandySvg src={IconShare} />
		</button>
	)
}
