import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ProviderRouter(props) {
	const [initDataUnsafe, initData] = useInitData();
	const navigate = useNavigate();
	const startParam = initDataUnsafe.start_param
	const [redirected, setRedirected] = useState(false)

	useEffect(() => {
		if (startParam && !redirected) {
			const type = startParam.substring(0, 2)
			const id = startParam.substring(2)
			setRedirected(true)
			if (id) {
				if (type === 'SH') {
					navigate('/projects/' + id)
				} else if (type === 'CT') {
					navigate('/categories/' + id)
				} else if (type === 'CP') {
					navigate('/discount/' + id)
				}
			}
		}
	}, [startParam, navigate, redirected]);

	return (
		props.children
	)
}
