import React from 'react';
import Page from '@/components/Page';
import ProjectsList from '../components/ProjectsList';

export default function ProjectsPage() {
	return (
    	<Page title='Проекты'>
			<ProjectsList search={true} />
	    </Page>
  	);
}
