import React from 'react';
// import { Provider as StoreProvider } from 'react-redux';
// import { store } from './store';
import { HelmetProvider } from 'react-helmet-async';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';

export default function Providers({children}) {
  	return (
		// {/* <StoreProvider store={store}> */}
		<WebAppProvider>
			<HelmetProvider>
				{children}
			</HelmetProvider>
		</WebAppProvider>
		// {/* </StoreProvider> */}
  	);
}

