import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react';
import CategoriesApi from '../api';
import { useInitData } from '@vkruglikov/react-telegram-web-app';

export default function CategoriesList() {
	const [cats, setCats] = useState([]);
	const location = useLocation()
	const [InitDataUnsafe, initData] = useInitData();

	useEffect(() => {
		CategoriesApi.getCategories({initData: initData}).then(function(result) {
			if (result.data) {
				setCats(result.data.categories)
			}
		})
	}, [initData])

	return (
		<div className='boxFolder'>
			{cats.map((cat) => (
				<Link to={'/categories/' + cat.id} className='boxFolderItem' key={cat.id} state={{cat: cat, from: location.pathname}}>
					<div className='boxFolderItem-info'>
						<div className='boxFolderItem-name'>{cat.title}</div>
					</div>
				</Link>
			))}
		</div>
	)
}
