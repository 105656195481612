import api from '@/api'

export default class CategoriesApi {
	static async getCategories(data = {}) {
		const http = api();
		return http.post('/category/all', data).then(function (response) {
			return response.data
		})

	}

	static async getCategory(data = {}) {
		const http = api();
		return http.post('/category/one', data).then(function (response) {
			return response.data
		})
	}
}
