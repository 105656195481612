import { useHapticFeedback, useInitData, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { MainButton } from '@vkruglikov/react-telegram-web-app';
import copy from "copy-to-clipboard";
import ProjectsApi from '../../api'
import Alert from "@/components/Alert";

export default function ProjectDiscountSimple() {
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const [initDataUnsafe, initData] = useInitData();
	const WebApp = useWebApp();

	const location = useLocation()
  	const coupon = location.state.coupon
	const [copied, setCopied] = useState(false)

	const handleCopy = (e = false) => {
		copy(coupon.coupon)
		if (e) {
			if (copied) return false;
			e.target.innerText = 'Скопировано!'
			impactOccurred('medium')
			setCopied(true)
			setTimeout(() => {
				setCopied(false)
				e.target.innerText = coupon.coupon
			}, 3000)
		}
	}

	const sendToBot = () => {
		impactOccurred('medium')

		ProjectsApi.sendToBot({
			id: coupon.id,
			initData: initData
		}).then(function(result) {
			WebApp.close()
		})
	}

	return (
		<>
			<div className='boxText'>
				<div className='boxText_header'>Промокод</div>
				<div className='boxText_body'>
					{coupon.coupon &&
						<div className={'boxLink' + (copied ? ' boxLink--grey' : '')} onClick={handleCopy}>
							{coupon.coupon}
						</div>
					}
					{(coupon.type_of_coupon == 0 && coupon.url_to_activate) &&
						<button className='btn btn--block btn--tt-none' onClick={() => {
							handleCopy()
							window.open(coupon.url_to_activate, '_blank').focus()
						}}>Скопировать и перейти на сайт</button>
					}
					{coupon.type_of_coupon == 20 &&
						<button className='btn btn--block btn--tt-none' onClick={() => {
							impactOccurred('heavy')
							window.open(coupon.url_to_activate, '_blank').focus()
						}}>Перейти на сайт</button>
					}
				</div>
				<div className='boxText_footer' style={{paddingRight: '45px'}}>Материал был загружен: {coupon.published}</div>
			</div>
			<MainButton
				text='Посмотреть в боте'
				onClick={sendToBot}
			/>
			{copied && <Alert>Промокод скопирован.</Alert>}
		</>
	)
}
