import { useHapticFeedback, useInitData, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ProjectsApi from '../../api'
import { MainButton } from '@vkruglikov/react-telegram-web-app';
import copy from "copy-to-clipboard";
import Alert from "@/components/Alert";

export default function ProjectDiscountComplex() {
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const [initDataUnsafe, initData] = useInitData();
	const WebApp = useWebApp();

	const location = useLocation()
  	const coupon = location.state.coupon
  	const shop = location.state.shop
	const [copied, setCopied] = useState(false)

	const handleCopy = (e = false) => {
		copy(coupon.coupon)
		impactOccurred('medium')
		if (e) {
			if (copied) return false;
			e.target.innerText = 'Скопировано!'
			setCopied(true)
			setTimeout(() => {
				setCopied(false)
				e.target.innerText = coupon.coupon
			}, 3000)
		}
	}

	const [error, setError] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const [showed, setShowed] = useState(false)
	const [copiedLink, setCopiedLink] = useState(false)

	const handleClickLink = () => {
		copy(coupon.url_for_check)
		impactOccurred('heavy')
		setCopiedLink(true)
	}

	useEffect(() => {
        const timer = setTimeout(() => setCopiedLink(false), 3000);
        return () => clearTimeout(timer);
    }, [copiedLink]);

	const input = useRef()

	const handleLinked = (e) => {
		setError(false)

		const data = {
			initData: initData,
			url: input.current.value,
			id: coupon.id
		}

		e.target.innerText = 'Идет проверка...'
		e.target.disabled = true

		ProjectsApi.getCouponComplex(data).then(function(result) {
			e.target.innerText = 'Отправить'
			e.target.disabled = false
			input.current.value = ''
			if (result.data.coupon) {
				coupon.coupon = result.data.coupon
				notificationOccurred('success')
				setLoaded(true)
			} else {
				notificationOccurred('error')
				setLoaded(false)
				setError(result.data.error)
			}
		})
	}

	const sendToBot = () => {
		impactOccurred('medium')

		ProjectsApi.sendToBot({
			id: coupon.id,
			initData: initData
		}).then(function(result) {
			WebApp.close()
		})
	}

	return (
		<>
			<div className='boxText'>
				<div className='boxText_header'>Промокод</div>
				<div className='boxText_body'>
					{!showed ?
						<button className='btn btn--block btn--tt-none' onClick={() => {
							impactOccurred('light')
							setShowed(true)
						}}>Получить промокод</button>
					: showed && !loaded ?
						<>
							<p><b>Инструкция для получения промокода:</b></p>
							<ol>
								<li>Добавь все нужные товары в корзину на сайте <a href={shop.url} target='_blank' rel='noreferrer'>{shop.title}</a>.</li>
								<li>Скопируйте ссылку из специального поля и вставьте её в новое окно в браузере. Откроется ваша корзина с товарами в ней.</li>
								<li>Скопируйте получившуюся ссылку (длинную) из браузера и вставьте в поле проверки.</li>
								<li>Если вы всё сделали верно, то после нажатия кнопки <b>"Отправить"</b> получите промокод.</li>
							</ol>
							<div className='boxLink' onClick={handleClickLink}>
								{coupon.url_for_check}
							</div>
							<button className='btn btn--block btn--tt-none' onClick={handleClickLink}>{!copied ? 'Скопировать ссылку' : 'Скопировано!'}</button>
							<br/><br/>
							<textarea rows={3} placeholder='Поле проверки (вставьте ссылку)' className='input' style={{marginBottom: '6px'}} ref={input} />
							{error && <div style={{color: 'red', marginBottom: '15px'}}>{error}</div>}
							<button className='btn btn--block btn--tt-none' onClick={handleLinked}>Отправить</button>
						</>
					:
						<>
							<div className={'boxLink' + (copied ? ' boxLink--grey' : '')} onClick={handleCopy}>
								{coupon.coupon}
							</div>
							<button className='btn btn--block btn--tt-none' onClick={() => {
								handleCopy()
								window.open(coupon.url_for_check, '_blank').focus()
							}}>Скопировать и перейти на сайт</button>
						</>
					}

				</div>
				<div className='boxText_footer' style={{paddingRight: '45px'}}>Материал был загружен: {coupon.published}</div>
			</div>
			{loaded &&
				<MainButton
					text='Посмотреть в боте'
					onClick={sendToBot}
				/>
			}
			{copied && <Alert>Промокод скопирован.</Alert>}
			{copiedLink && <Alert>Ссылка скопирована.</Alert>}
		</>
	)
}
